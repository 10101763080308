import * as React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import * as z from 'zod'
import { isConsentRequired, setConsentCookie, updateGtmConsents } from './gtmConsents'
import { FormCheckbox, useZodForm } from '@controls/Form'
import { Button, Collapse } from '@mui/material'
import styles from './CookieConsent.module.css'
import { useLanguage } from '@hooks/useLanguage'
import { RouterLink } from '@controls/buttons'

const FormType = z.object({
  required: z.boolean(),
  marketing: z.boolean()
})
type FormType = z.infer<typeof FormType>

export const CookieConsent: React.FC = () => {
  const { t } = useTranslation()
  const { routes } = useLanguage()

  const [showConsent, setShowConsent] = React.useState(isConsentRequired)
  const [expanded, setExpanded] = React.useState(false)

  const setConsentOptions = React.useCallback((marketing: boolean) => {
    setConsentCookie(marketing)
    updateGtmConsents(marketing)
    setShowConsent(false)
  }, [])

  const handleAcceptAll = React.useCallback(() => {
    setConsentOptions(true)
  }, [setConsentOptions])

  const handleSubmit = React.useCallback(
    (values: FormType) => {
      setConsentOptions(values.marketing)
    },
    [setConsentOptions]
  )

  const form = useZodForm({
    schema: FormType,
    values: { required: true, marketing: false }
  })

  return (
    showConsent && (
      <div className={styles.cookieConsent__backdrop}>
        <div className={styles.cookieConsent__paper}>
          <form onSubmit={form.handleSubmit(handleSubmit)}>
              <p className={styles.cookieConsent__title}>{t('global.forms.cookieConsent.title')}</p>
            <Trans
              i18nKey="global.forms.cookieConsent.text"
              components={{
                linkToPolicy: <RouterLink className={styles.consentLabel} to={routes.PrivacyPolicy} />
              }}
            />
              <Collapse in={expanded} timeout="auto" unmountOnExit className={styles.cookieConsent__collapse}>
                <p className={styles.cookieConsent__subtitle}>{t('global.forms.cookieConsent.additional.title')}</p>
                <p>{t('global.forms.cookieConsent.additional.text')}</p>
                <div className={styles.cookieConsent__checkboxes}>
                  <FormCheckbox
                    control={form.control}
                    name="required"
                    disabled
                    label={t('global.forms.cookieConsent.consentTypes.required')}
                  />
                  <FormCheckbox
                    control={form.control}
                    name="marketing"
                    label={t('global.forms.cookieConsent.consentTypes.marketing')}
                  />
                </div>
              </Collapse>
              <div className={styles.cookieConsent__actions}>
                <Button color="primary" variant="contained" onClick={handleAcceptAll}>
                  {t('global.forms.cookieConsent.buttons.acceptAll')}
                </Button>
                {expanded && (
                  <Button type="submit" color="secondary" variant="outlined">
                    {t('global.forms.cookieConsent.buttons.acceptSelected')}
                  </Button>
                )}
                {!expanded && (
                  <Button color="secondary" variant="outlined" onClick={() => setExpanded(true)}>
                    {t('global.forms.cookieConsent.buttons.customize')}
                  </Button>
                )}
              </div>
          </form>
      </div>
</div>
)
)
}
