export const CookieGroup = ['required', 'marketing'] as const
export type GtmConsentAction = 'default' | 'update'
export type GtmConsentValue = 'granted' | 'denied' | 'auto'
export type CookieGroupType = (typeof CookieGroup)[number]

declare global {
  function setGtmConsents(action: GtmConsentAction, value: GtmConsentValue): void
  function readConsentCookie(): ReadonlyArray<CookieGroupType> | undefined
  function writeConsentCookie(values: ReadonlyArray<CookieGroupType>, expires: boolean): void
}

export const isConsentRequired = (): boolean => !readConsentCookie()

export const updateGtmConsents = (marketing: boolean): void => {
  setGtmConsents('update', marketing ? 'granted' : 'denied')
  setTimeout(() => {
    // eslint-disable-next-line functional/immutable-data
    dataLayer.push({ event: 'Consent Updated' })
  }, 500)
}

export const setConsentCookie = (marketing: boolean): void =>
  writeConsentCookie(marketing ? ['required', 'marketing'] : ['required'], true)
