/// <reference types="vite-plugin-svgr/client" />
import * as React from 'react'
import { FieldPath, FieldValues } from 'react-hook-form'
import { CheckboxElement, CheckboxElementProps } from 'react-hook-form-mui'
import SvgCheckedIcon from '@assets/icons/checkedIconInSquare.svg?react'
import SvgUncheckedIconLight from '@assets/icons/uncheckedIconLight.svg?react'
import SvgUncheckedIconDark from '@assets/icons/uncheckedIconDark.svg?react'
import { useTheme } from '@hooks/useTheme'
import { CommonFormFieldProps } from '../types'
import styles from './FormCheckbox.module.css'

export type FormCheckboxProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
  // TODO: Resolve why "validation" & "ref" properties doesn't pass type check
> = Omit<CheckboxElementProps<TFieldValues, TName>, 'icon' | 'checkedIcon' | 'validation' | 'ref'> &
  CommonFormFieldProps<TFieldValues>

export function FormCheckbox<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({ disableRipple = true, control, ...props }: FormCheckboxProps<TFieldValues, TName>): React.ReactElement {
  const { currentTheme } = useTheme()
  return (
    <div className={styles.formCheckboxContainer}>
      <CheckboxElement
        disableRipple={disableRipple}
        icon={currentTheme.palette.mode === 'dark' ? <SvgUncheckedIconDark /> : <SvgUncheckedIconLight />}
        checkedIcon={<SvgCheckedIcon />}
        control={control}
        {...props}
      />
    </div>
  )
}
